import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [inputData, setInputData] = useState({
    url: '',
    vCard: { name: '', phone: '', email: '', company: '', address: '' },
  });
  const [qrCode, setQrCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const [isVCard, setIsVCard] = useState(false);

  // Fetch the base URL when the component mounts
  useEffect(() => {
    const fetchBaseUrl = async () => {
      try {
        const response = await fetch('https://qr.webwefy.com/backend/generate.php?getBaseUrl=true');
        const data = await response.json();
        setBaseUrl(data.baseUrl || '');
      } catch (error) {
        setErrorMessage('Failed to load base URL.');
      }
    };
    fetchBaseUrl();
  }, []);

  // Handle URL and vCard input change dynamically
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'url') {
      setInputData((prev) => ({ ...prev, url: value }));
    } else {
      setInputData((prev) => ({
        ...prev,
        vCard: { ...prev.vCard, [name]: value },
      }));
    }
  };

  // Handle Radio Button Change
  const handleRadioChange = (e) => {
    setIsVCard(e.target.value === 'vCard');
  };

  // Generate QR Code dynamically based on URL or vCard
  const generateQrCode = async () => {
    const dataToSend = isVCard ? formatVCardData() : { url: inputData.url };

    if (!dataToSend.url && !dataToSend.vCard) {
      setErrorMessage('Please enter valid data.');
      return;
    }

    if (!baseUrl) {
      setErrorMessage('Base URL not loaded yet.');
      return;
    }

    setLoading(true);
    setErrorMessage('');

    try {
      const response = await fetch(`${baseUrl}generate.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSend),
      });
      const data = await response.json();
      if (data.success) {
        setQrCode(data.qrCodeUrl);
      } else {
        setErrorMessage('Error generating QR code.');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Format vCard data into the appropriate string format for adding to contacts
  const formatVCardData = () => {
    const { name, phone, email, company, address } = inputData.vCard;
    return {
      vCard: `BEGIN:VCARD
VERSION:3.0
FN:${name}
TEL:${phone}
EMAIL:${email}
ORG:${company}
ADR:${address}
N:${name.split(' ')[1] || ''};${name.split(' ')[0] || ''};;;
END:VCARD`
    };
  };

  const handleNewQrCode = () => {
    // Reset all state values in a clean and dynamic manner
    setInputData((prev) => ({
      ...prev,
      url: '', // Reset the URL field
      vCard: { name: '', phone: '', email: '', company: '', address: '' }, // Reset vCard fields
    }));
    setQrCode('');
    setErrorMessage('');
    setIsVCard(false);
  };

  // Check if the button should be disabled
  const isGenerateButtonDisabled = () => {
    if (isVCard) {
      // Disable if vCard name or phone is empty
      return !inputData.vCard.name || !inputData.vCard.phone;
    } else {
      // Disable if URL is empty for the link option
      return !inputData.url;
    }
  };

  return (
    <div className="container">
      <header className="header">
        {baseUrl && (
          <img
            src={`${baseUrl}../img/logo-03.png`} // Dynamically use baseUrl from the PHP backend
            alt="Logo"
            className="logo"
          />
        )}
        <h1>QRiT!</h1>
      </header>

      {/* Show form inputs only if qrCode is not generated */}
      {!qrCode && (
        <div className="form-container">
          <div className="radio-container">
            <label className="radio-option">
              <input
                type="radio"
                name="qrType"
                value="url"
                checked={!isVCard}
                onChange={handleRadioChange}
                id="urlOption"
              />
              {baseUrl && (
                <img
                  src={`${baseUrl}../img/link.png`} // Dynamically use baseUrl for the URL option image
                  alt="URL"
                  className="radio-img"
                />
              )}
            </label>
            <label className="radio-option">
              <input
                type="radio"
                name="qrType"
                value="vCard"
                checked={isVCard}
                onChange={handleRadioChange}
                id="vCardOption"
              />
              {baseUrl && (
                <img
                  src={`${baseUrl}../img/vcard.png`} // Dynamically use baseUrl for the vCard option image
                  alt="vCard"
                  className="radio-img"
                />
              )}
            </label>
          </div>

          {/* URL input */}
          {!isVCard && (
            <div className="url-input">
              <input
                type="text"
                name="url"
                value={inputData.url}
                onChange={handleChange}
                placeholder="Enter URL here"
                required
              />
            </div>
          )}

          {/* vCard input */}
          {isVCard && (
            <div className="vcard-form">
              <h2>Create vCard</h2>
              <input
                type="text"
                name="name"
                value={inputData.vCard.name}
                onChange={handleChange}
                placeholder="Full Name"
                required
              />
              <input
                type="tel"
                name="phone"
                value={inputData.vCard.phone}
                onChange={handleChange}
                placeholder="Phone Number"
                required
              />
              <input
                type="email"
                name="email"
                value={inputData.vCard.email}
                onChange={handleChange}
                placeholder="Email"
              />
              <input
                type="text"
                name="company"
                value={inputData.vCard.company}
                onChange={handleChange}
                placeholder="Company"
              />
              <input
                type="text"
                name="address"
                value={inputData.vCard.address}
                onChange={handleChange}
                placeholder="Address"
              />
            </div>
          )}

          {/* Generate QR Code Button */}
          <button onClick={generateQrCode} disabled={isGenerateButtonDisabled()}>
            <i className="fas fa-qrcode"></i> Generate QR Code
          </button>
        </div>
      )}

      {loading && (
        <div className="loader">
          <div className="spinner"></div>
          <p>Generating QR Code...</p>
        </div>
      )}

      {/* Show QR code if it's generated */}
      {qrCode && (
        <div className="qr-code-container">
          <img src={qrCode} alt="QR Code" />
          <a href={qrCode} download className="download-overlay">
            <i className="fas fa-download"></i>
          </a>
        </div>
      )}

      {errorMessage && <p className="error">{errorMessage}</p>}

      {/* Show 'New QR Code' button only after QR code is generated */}
      {qrCode && (
        <div>
          <button onClick={handleNewQrCode}>
            <i className="fas fa-plus"></i> New QR Code
          </button>
        </div>
      )}

      <footer className="footer">
        Built with love by <a href="https://www.linkedin.com/in/mrbounkoub/" target="_blank" rel="noopener noreferrer">@hamzapeps</a>. Enjoy!
      </footer>
    </div>
  );
}

export default App;
